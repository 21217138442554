import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import Project from './features/project/Project'
import Profile from './features/profile/Profile'
import Resume from './features/resume/Resume'

import 'bootstrap/dist/css/bootstrap.min.css';

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          index
          element={<Resume />}
        />
        <Route path="/project" element={<Project />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/resume" element={<Resume />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  rootElement
);