import { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './Profile.css';
import '../../styles/type.css';
import '../../styles/input.css';
import axios from "axios";

import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import MessageForm2 from '../message-form/MessageForm'

const bioMapperText = "A suite of tools for processing territory positioning of genes/chromsomes and their relative position of nucleus shells.";



function MessageForm() {

  function resetForm() {
    let name = document.getElementById('messageFormUserName').value = "";
    let email = document.getElementById('messageFormUserEmail').value = "";
    let message = document.getElementById('messageFormUserMessage').value = "";

    let dataView = document.getElementById('messageFormData');
    let sendingView = document.getElementById('messageFormSending');
    let sentView = document.getElementById('messageFormSent');
    let errorView = document.getElementById('messageFormError');

    dataView.style.display = 'flex';
    sendingView.style.display = 'none';
    sentView.style.display = 'none';
    errorView.style.display = 'none';
  }

  function handleSubmit(e) {
    e.preventDefault();

    let dataView = document.getElementById('messageFormData');
    let sendingView = document.getElementById('messageFormSending');
    let sentView = document.getElementById('messageFormSent');
    let errorView = document.getElementById('messageFormError');

    let name = document.getElementById('messageFormUserName');
    let email = document.getElementById('messageFormUserEmail');
    let message = document.getElementById('messageFormUserMessage');
    let error = false;

    if (name.value == undefined || name.value == "") {
      name.placeholder = "You forgot to add a name!";
      name.style.border = "1px solid red";
      error = true;
    }

    if (email.value == undefined || email.value == "") {
      email.placeholder = "You forgot to add an email!";
      email.style.border = "1px solid red";
      error = true;
    }

    if (message.value == undefined || message.value == "") {
      message.placeholder = "You forgot to add a message!";
      message.style.border = "1px solid red";
      error = true;
    }

    if (!error) {
      dataView.style.display = 'none';
      sendingView.style.display = 'flex';

      try {
        const messageData = { "uName": name.value, "uEmail": email.value, "uMessage": message.value };

        var qs = require('qs');
        data: qs.stringify(messageData)
        axios({
          method: 'post',
          url: '/usermessage.php',
          headers: {
            "Content-Type": 'text/json; charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
          },
          data: messageData
        })
          .then((response) => {
            if (response.data.result == "OK") {
              sendingView.style.display = 'none';
              sentView.style.display = 'flex';
            }
            else {
              sendingView.style.display = 'none';
              errorView.style.display = 'flex';
            }
          });
      }
      catch (e) {
        dataView.style.display = 'none';
        sendingView.style.display = 'none';
        sentView.style.display = 'none';
        errorView.style.display = 'flex';
      }
    }
  }

  return (
    <div>
      <div id="messageFormData" className="profileFormHeader">
        <h2 className="profileFormTitle">Want to contact me?</h2>
        <h3 className="profileFormSubtitle">It only takes two seconds, and I'll write back to you.</h3>
        <form onSubmit={handleSubmit} className="profileForm">
          <input id="messageFormUserName" tabindex="1" className="profileInput" type="text" placeholder="Name" />
          <input id="messageFormUserEmail" tabindex="2" className="profileInput" type="email" placeholder="Email" />
          <textarea id="messageFormUserMessage" tabindex="3" rows="7" className="profileTextArea" placeholder="Message"></textarea>
          <input className="profileSubmitButton" tabindex="4" type="submit" />
        </form>
      </div>
      <div style={{ display: 'none' }} id="messageFormSending"><h2 className="profileFormTitle">Sending</h2></div>
      <div style={{ display: 'none', flexDirection: 'column' }} id="messageFormSent">
        <h2 className="profileFormTitle">Sent</h2>
        <button onClick={resetForm} className="resetForm">Send another message?</button>
      </div>
      <div style={{ display: 'none', flexDirection: 'column' }} id="messageFormError">
        <h2 className="profileFormTitle">Error</h2>
        <button onClick={resetForm} className="resetForm">Try again?</button>
      </div>
    </div>
  );
}

const Profile = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function OpenLink(url) {
    window.open(url);
  }

  return (


    <div className="profileContentWrapper">
      <style type="text/css">
        {`
    .btn-flat {
      border: 1px solid #df6a0f;
      color: #df6a0f;
      text-align: left;
      font-size: 12;
    }
    .btn-flat:hover {
      background-color: #df6a0f;
      color: #ffffff;
    }
    .btn-orange {
      border: 1px solid #ffffff;
      color: #ffffff;
      text-align: left;
      font-size: 12;
    }
    .btn-orange:hover {
      background-color: #ffffff;
      color: #df6a0f;
    }
    `}
      </style>

      <div className="profileAboutWrapper">
        <div className="profileTitlePicWrap">
        <img src={process.env.PUBLIC_URL + '/images/me.jpeg'} className="profilePersonalPic" />
        <h1 className="profileHeader">A little about me</h1>
        </div>
        <div className="profileAboutBlock">
          <div>
            
          </div>
          <div className="profileAboutBody">
            <p>I am a collaborative and adaptable full stack developer with a focus on UI/UX and element design. Having an eye for detail, I strive for excellence within myself, the work I deliver, and those I work with. I enjoy and believe in sharing new skills and knowledge gained with the team.</p>
            <p>My experience covers web design and development, database design and queries, backend logic, software QA and managing remote and distributed teams.</p>
            <p>I have further experience in resource planning, coaching and the Agile development methodology.</p>
          </div>
        </div>
      </div>


      <div className="profileContactWrapper">

        <div class="profileContactLeftSideWrapper">

          <div className="profileContactButtonWrapper">
            <Button size="lg" variant="orange" onClick={handleShow}>Contact me</Button>
          </div>

          <div className="profileContactLeftSideItemWrapper">
            <div className="profileContactSideItemInnerWrapper">
              <div className="profileContactSideItem">
                <img src={process.env.PUBLIC_URL + '/images/LI-In-Bug.png'} className="profileContactSideItemIcon" />
                <span className="profileContactSideItemTitle">LinkedIn</span>
              </div>

              <div>
                <Button size="sm" variant="flat" onClick={() => OpenLink('https://www.linkedin.com/in/matthew-oneill-bsc')}>Connect on LinkedIn</Button>
              </div>
            </div>
          </div>

          <div className="profileContactLeftSideItemWrapper">
            <div className="profileContactSideItemInnerWrapper">
              <div className="profileContactSideItem">
                <img src={process.env.PUBLIC_URL + '/images/brunel.jpeg'} className="profileContactSideItemIcon" />
                <span className="profileContactSideItemTitle">Brunel University</span>
              </div>

              <div>
                <Button size="sm" variant="flat" onClick={() => OpenLink('https://www.brunel.ac.uk/people/matthew-oneill')}>View profile</Button>
              </div>
            </div>
          </div>
        </div>
        
        <div className="profileContactRightSideWrapper">
          <div className="profileContactRightSideItemWrapper">
            <div className="profileContactSideItemInnerWrapper">
              <div className="profileContactSideItem">
                <img src={process.env.PUBLIC_URL + '/images/Instagram.png'} className="profileContactSideItemIcon" />
                <span className="profileContactSideItemTitle">Instagram</span>
              </div>

              <div className="profileContactSideItemGallery">
                <img src={process.env.PUBLIC_URL + '/images/ig/keela.png'} className="igImage" />
                <img src={process.env.PUBLIC_URL + '/images/ig/summer.png'} className="igImage" />
                <img src={process.env.PUBLIC_URL + '/images/ig/me.png'} className="igImage" />
                <img src={process.env.PUBLIC_URL + '/images/ig/me.png'} className="igImage1200" />
                <img src={process.env.PUBLIC_URL + '/images/ig/me.png'} className="igImage1400" />
              </div>

              <div className="profileContactSideItemGallery">
                <img src={process.env.PUBLIC_URL + '/images/ig/keela.png'} className="igImage992" />
                <img src={process.env.PUBLIC_URL + '/images/ig/summer.png'} className="igImage992" />
                <img src={process.env.PUBLIC_URL + '/images/ig/me.png'} className="igImage992" />
                <img src={process.env.PUBLIC_URL + '/images/ig/me.png'} className="igImage1200" />
                <img src={process.env.PUBLIC_URL + '/images/ig/me.png'} className="igImage1400" />
              </div>

              <div>
                <Button size="sm" variant="flat" style={{marginTop: 27}} onClick={() => OpenLink('https://www.instagram.com/lunarfever/')}>Follow on Instagram</Button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="end" className="profileOffCanvas">
        <Offcanvas.Header closeButton closeVariant="white">
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h2 style={{color: '#ffffff', paddingLeft: 0, paddingBottom: 10, borderBottom: '1px solid white'}}>Contact Me</h2>
          <MessageForm2 />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default Profile;