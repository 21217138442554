import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { useState } from "react";
import './Resume.css';
import '../../styles/type.css';
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas';
import MessageForm2 from '../message-form/MessageForm'

const bioMapperText = "A suite of tools for processing territory positioning of genes/chromsomes and their relative position of nucleus shells.";
const Resume = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const DownloadResume = () => {
    window.open(process.env.PUBLIC_URL + "/pdfs/mattoneill-cv.pdf");
  }

  return (
    <div className="resumePage">

      <div>
        <div className="resumeContentWrapper">
          <div className="resumeMainBlockHeaderWrapper">
            <h1 className="resumeHeader">Experience</h1>
          </div>
          <div className="resumeExperienceOuterWrapper">
            <h3 className="resumeEmployer">Sharp Electronics</h3>
            <h3 className="resumePositionTitle">Software Development and Cloud Platform Manager, London</h3>
            <h4 className="resumeEmploymentPeriod">June 2021 - Present</h4>
            <p>A split role with responsibility for the European Support Business Centre (ESBC) development team; resource planning and ensuring the agile methodology and ceremonies are adhered to. Coaching and training of developers toward their professional and personal goals. Review status of development projects, participate in code reviews and
              review support tickets and team performance against SLAs.</p>
            <p>Manage the QA of Sharp global cloud products within the European region working with European limited risk distributors to ensure feature, function and localisation are suitable for release, working with development teams in India, Japan and America to resolve issues.</p>
          </div>

          <div className="resumeExperienceOuterWrapper">
            <h3 className="resumeEmployer">Sharp Electronics</h3>
            <h3 className="resumePositionTitle">Development Team Lead and Cloud Technical Support, London</h3>
            <h4 className="resumeEmploymentPeriod">December 2020 - June 2021</h4>
          </div>

          <div className="resumeExperienceOuterWrapper">
            <h3 className="resumeEmployer">Sharp Electronics</h3>
            <h3 className="resumePositionTitle">Development Team Lead (Step-up), London</h3>
            <h4 className="resumeEmploymentPeriod">March 2020 - December 2020</h4>
          </div>

          <div className="resumeExperienceOuterWrapper">
            <h3 className="resumeEmployer">Sharp Electronics</h3>
            <h3 className="resumePositionTitle">Software Developer, London</h3>
            <h4 className="resumeEmploymentPeriod">August 2009 - March 2020</h4>
            <p style={{ lineHeight: 1.5, color: '#555555', fontSize: 16, marginTop: 0 }}>Active development of ESBC solution portfolio. Taking the lead for UI/UX components, delivered in HTML, CSS, PHP, VLML, JSP.   Writing SQL for query and database updates/restructure, also involved in backend development in Java and C# as and when required.</p>
          </div>

          <div className="resumeExperienceOuterWrapper">
            <h3 className="resumeEmployer">Tesco</h3>
            <h3 className="resumePositionTitle">Dairy, Meat and Poultry Manager, Eastbourne</h3>
            <h4 className="resumeEmploymentPeriod">February 2008 - August 2009</h4>
            <p style={{ lineHeight: 1.5, color: '#555555', fontSize: 16, marginTop: 0 }}>Responsible for the resourcing and daily running of the department, delivering against company KPIs, maintaining a consistent experience for customers in-store or online.</p>
          </div>

          <div className="resumeExperienceOuterWrapper" style={{ borderBottomWidth: 0 }}>
            <h3 className="resumeEmployer">Indicate</h3>
            <h3 className="resumePositionTitle">Multimedia and Video Delivery, Eastbourne</h3>
            <h4 className="resumeEmploymentPeriod">2003 - February 2008</h4>
            <p style={{ lineHeight: 1.5, color: '#555555', fontSize: 16, marginTop: 0 }}>Self-employed as a multimedia content creator, producing interactive CDs, DVDs and websites. Providing video editing, DVD authoring, subtitling and cover design.</p>
          </div>
        </div>

        <div className="resumeContentWrapper" style={{ marginTop: 15 }}>
          <div className="resumeMainBlockHeaderWrapper">
            <h1 className="resumeHeader">Education</h1>
          </div>
          <div className="resumeEducationOuterWrapper">
            <h3 className="educationCourse">PhD (Current; part-time), Bioscience/Computational Biology</h3>
            <h3 className="resumeStudyQualification">Genomics and DNA Sequencing research commenced April 2021</h3>
            <h4 className="resumeStudyInstitution">Brunel University, London</h4>
          </div>
          <div className="resumeEducationOuterWrapper">
            <h3 className="educationCourse">Computing and IT, Bachelor Degree BSc (Honours)</h3>
            <h3 className="resumeStudyQualification">2016 - 2020</h3>
            <h4 className="resumeStudyInstitution">The Open University</h4>
          </div>
          <div className="resumeEducationOuterWrapper" style={{ borderBottomWidth: 0 }}>
            <h3 className="educationCourse">Computing and IT, Diploma of Higher Education</h3>
            <h3 className="resumeStudyQualification">2012-2016</h3>
            <h4 className="resumeStudyInstitution">The Open University</h4>
          </div>
        </div>



      </div>
      <div className="sideBarWrapper">
        <div className="resumeDownloadOuterWrapper">
          <h3 className="resumeSideTitle">Download</h3>
          <p>You can download a pdf version of my latest resume here.</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40, justifyContent: 'center' }}>
            <div><img width="80" src={process.env.PUBLIC_URL + '/images/download.svg'} /></div>
            <Button style={{ marginTop: 20 }} onClick={DownloadResume} size="sm" variant="outline-success">Download PDF</Button>
          </div>
        </div>

        <div className="resumeContact">
          <Button size="lg" variant="outline-light" onClick={handleShow}>Contact me</Button>
        </div>

        <div className="resumeSkillsOuterWrapper">
          <h3 className="resumeSideTitle" style={{ marginBottom: 30 }}>Skills</h3>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', borderBottom: '1px solid #eeeeee', marginBottom: 15 }}>
            <img width="50" src={process.env.PUBLIC_URL + '/images/webdev.svg'} style={{ paddingRight: 10 }} />
            <div>
              <h3 className="resumeSkillTitle">Web Development</h3>
              <p>HTML, CSS, Javascript, PHP, JSON, SOAP, JSP, VLML</p>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', borderBottom: '1px solid #eeeeee', marginBottom: 15 }}>
            <img width="30" src={process.env.PUBLIC_URL + '/images/graphicdesign.svg'} style={{ paddingRight: 10 }} />
            <div>
              <h3 className="resumeSkillTitle">Graphic Design</h3>
              <p>Illustrator, Photoshop</p>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', borderBottom: '1px solid #eeeeee', marginBottom: 15 }}>
            <img width="30" src={process.env.PUBLIC_URL + '/images/backend.svg'} style={{ paddingRight: 10 }} />
            <div>
              <h3 className="resumeSkillTitle">Backend Development</h3>
              <p>C, C++, C#, Java</p>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', borderBottom: '1px solid #eeeeee', marginBottom: 15 }}>
            <img width="30" src={process.env.PUBLIC_URL + '/images/database.svg'} style={{ paddingRight: 10 }} />
            <div>
              <h3 className="resumeSkillTitle">Database</h3>
              <p>PostgreSQL, MySql</p>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', borderBottom: '1px solid #eeeeee', marginBottom: 15 }}>
            <img width="30" src={process.env.PUBLIC_URL + '/images/javascript.svg'} style={{ paddingRight: 10 }} />
            <div>
              <h3 className="resumeSkillTitle">JS Frameworks</h3>
              <p>ECMAScript, React, React Native</p>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', borderBottom: '1px solid #eeeeee', marginBottom: 15 }}>
            <img width="30" src={process.env.PUBLIC_URL + '/images/workflow.svg'} style={{ paddingRight: 10 }} />
            <div>
              <h3 className="resumeSkillTitle">Workflow Design</h3>
              <p>UI, UX, AX, DX</p>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <img width="30" src={process.env.PUBLIC_URL + '/images/custom.svg'} style={{ paddingRight: 10 }} />
            <div>
              <h3 className="resumeSkillTitle">Custom Dev Tools</h3>
              <p>Daily StandUp, Keep-Alive</p>
            </div>
          </div>
        </div>
        <div></div>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="end" style={{backgroundColor: '#0b7634'}}>
        <Offcanvas.Header closeButton closeVariant="white">
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h2 style={{ color: '#ffffff', paddingLeft: 0, paddingBottom: 10, borderBottom: '1px solid white' }}>Contact Me</h2>
          <MessageForm2 />
        </Offcanvas.Body>
      </Offcanvas>

    </div>
  )
}

export default Resume;