import axios from "axios";

const MessageForm = () => {

    function resetForm() {
        let name = document.getElementById('messageFormUserName').value = "";
        let email = document.getElementById('messageFormUserEmail').value = "";
        let message = document.getElementById('messageFormUserMessage').value = "";

        let dataView = document.getElementById('messageFormData');
        let sendingView = document.getElementById('messageFormSending');
        let sentView = document.getElementById('messageFormSent');
        let errorView = document.getElementById('messageFormError');

        dataView.style.display = 'flex';
        sendingView.style.display = 'none';
        sentView.style.display = 'none';
        errorView.style.display = 'none';
    }

    function handleSubmit(e) {
        e.preventDefault();

        let dataView = document.getElementById('messageFormData');
        let sendingView = document.getElementById('messageFormSending');
        let sentView = document.getElementById('messageFormSent');
        let errorView = document.getElementById('messageFormError');

        let name = document.getElementById('messageFormUserName');
        let email = document.getElementById('messageFormUserEmail');
        let message = document.getElementById('messageFormUserMessage');
        let error = false;

        if (name.value == undefined || name.value == "") {
            name.placeholder = "You forgot to add a name!";
            name.style.border = "1px solid white";
            error = true;
        }

        if (email.value == undefined || email.value == "") {
            email.placeholder = "You forgot to add an email!";
            email.style.border = "1px solid white";
            error = true;
        }

        if (message.value == undefined || message.value == "") {
            message.placeholder = "You forgot to add a message!";
            message.style.border = "1px solid white";
            error = true;
        }

        if (!error) {
            dataView.style.display = 'none';
            sendingView.style.display = 'flex';

            try {
                const messageData = { "uName": name.value, "uEmail": email.value, "uMessage": message.value };

                var qs = require('qs');
                data: qs.stringify(messageData)
                axios({
                    method: 'post',
                    url: '/usermessage.php',
                    headers: {
                        "Content-Type": 'text/json; charset=UTF-8',
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
                    },
                    data: messageData
                })
                    .then((response) => {
                        if (response.data.result == "OK") {
                            sendingView.style.display = 'none';
                            sentView.style.display = 'flex';
                        }
                        else {
                            sendingView.style.display = 'none';
                            errorView.style.display = 'flex';
                        }
                    });
            }
            catch (e) {
                dataView.style.display = 'none';
                sendingView.style.display = 'none';
                sentView.style.display = 'none';
                errorView.style.display = 'flex';
            }
        }
    }


    return (
        <div>
            <div id="messageFormData" className="profileFormHeader">
                <form onSubmit={handleSubmit} className="profileForm">
                    <input id="messageFormUserName" tabindex="1" className="profileInput" type="text" placeholder="Name" />
                    <input id="messageFormUserEmail" tabindex="2" className="profileInput" type="email" placeholder="Email" />
                    <textarea id="messageFormUserMessage" tabindex="3" rows="7" className="profileTextArea" placeholder="Message"></textarea>
                    <input className="profileSubmitButton" tabindex="4" type="submit" />
                </form>
            </div>
            <div style={{ display: 'none' }} id="messageFormSending"><h2 className="profileFormTitle">Sending</h2></div>
            
            <div style={{ display: 'none', flexDirection: 'column' }} id="messageFormSent">
                <h2 className="profileFormTitle">Sent</h2>
                <button onClick={resetForm} className="resetForm">Send another message?</button>
            </div>
            
            <div style={{ display: 'none', flexDirection: 'column' }} id="messageFormError">
                <h2 className="profileFormTitle">Error</h2>
                <button onClick={resetForm} className="resetForm">Try again?</button>
            </div>
        </div>
    )
}

export default MessageForm;



