import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import './ProjectItem.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-bootstrap/Carousel'
import Ratio from 'react-bootstrap/Ratio'

const ProjectItem = ({ item, color, title, type, tech, image, text }) => {

  const bgImage = process.env.PUBLIC_URL + '/images/portfolio/' + image;

  const linkAddr = "portfolio/" + item.name;


  return (
    <div className="profileItem">

      {/* <div className="profileBG">
        <img className="profileFullImage" src={process.env.PUBLIC_URL + '/images/portfolio/' + image} />
      </div> */}

      <div className="profilePadding">
        <h2 className="portfolioTileTitle">{item.title}</h2>
        <h3 className="profileTileType">{item.area}</h3>
        <h3 className="profileTileTech">{item.tech}</h3>

        <p className="profileStrap">{item.strap}</p>

        <div style={{ marginBottom: 20 }}>
          {
            //output intro text lines
            item.text.map(aItem => (<p className="profileBody">{aItem}</p>))
          }
        </div>
        {/* <div className="profileMoreLinkWrapper">
          <Link className="profileMoreLink" to={linkAddr}>{item.link}<img className='arrowIcon' src={process.env.PUBLIC_URL + '/images/link-arrow.svg'} alt="SVG as an image" /></Link>
        </div> */}
        <div className="carouselSize">
        
          <Carousel variant="dark">
          {
              item.images.map(aItem => (
                <Carousel.Item style={{padding: 50}}>
                 <Ratio aspectRatio="16x9">
                 <img
                  src={process.env.PUBLIC_URL + '/images/portfolio/' + item.name + '/' + aItem}
                alt="Second slide"
              />
              </Ratio>
            </Carousel.Item>
              ))
            }

          </Carousel>
          
          {/* <Carousel showStatus={false}>
            {
              item.images.map(aItem => (
                <div>
                  <img src={process.env.PUBLIC_URL + '/images/portfolio/' + item.name + '/' + aItem} />
                </div>
              ))
            }
          </Carousel> */}







        </div>
      </div>
    </div>
  )
}

export default ProjectItem;