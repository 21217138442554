import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './Project.css';
import ProjectItem from "./ProjectItem";
import '../../styles/type.css';

const bioMapperText = "A suite of tools for processing territory positioning of genes/chromsomes and their relative position of nucleus shells.";

var profileItems = {

  'biomapper': {
    'name': 'biomapper',
    'title': 'BioMapper',
    'area': 'Research and Development',
    'tech': 'C#, OpenCV',
    'strap': 'Performance leading research',
    'text': [
      'Bio mapper is a new tool that I developed to aid researchers with territory location of genes and chromosomes in nucleus shells.',
      'Developed to run on standard Windows 10 devices, the software further improves researcher and clinicians workflows by allowing them to work anywhere, anytime.',
      'The developed application, accepts all image types, and processes them live, allowing instant feedback. Data can be exported for further analysis.'
    ],
    'images':['biomapper-1.png'],
    'link': 'Find out more about BioMapper'

  },
  'editdistance': {
    'name': 'editdistance',
    'title': 'Edit Distance',
    'area': 'Research and Development',
    'tech': 'C++, PHP, JavaScript',
    'strap': 'Refining effeciency algorithms',
    'text': [
      'Stage 1/3 of an active research project.',
      'The aim of the project is to further understand performance bottle necks in the processing of large DNA/RNA datasets.',
      'Algorithms have been written in pure C++, allowing incredibly fast processing of the input sequences, validation is ongoing against the syntheically generated data sets.',
      'A PHP/Javascript port of the project has been created, to help other users understand the edit distance algorithm and backtrace visually'
    ],
    'images':[],
    'link': 'Learn more about Edit Distance'
  },
  'dailystandup': {
    'name': 'dailystandup',
    'title': 'Daily Stand Up',
    'area': 'Developer Experience (DX) Tool Development',
    'tech': 'C#, Win UI',
    'strap': 'Removing Communication Blockers',
    'text': [
      'During COVID, the difficulties of remote working, furlough and illness, along with general balancing of life and family commitments, created an environment of uncertainty.',
      'My development team had to quickly adapt to working remotely. One difficuly we experienced early on was how to run, connect and manage our daily stand up?',
      'In repsonse to this daily uncertainty, I created the daily stand up tool. A small utility that allowed any developer to run the stand up, connect to the online meeting and Jira board via a single button click while allowing management of attendees talk time and order of the stand up.',
    ],
    'images':['dailystandup-1.png', 'dailystandup-2.png'],
    'link': 'See how the Daily Stand Up tool works'
  },
  'publett': {
    'name': 'publett',
    'title': 'Medical Start Up',
    'area': 'Graphic Design',
    'tech': 'Adobe Illustrator',
    'strap': 'Simple Elegent Icons',
    'text': [
      'Working directly with the start up developers and designers, I created a collection of assets for their website, and mobile applications.',
      'The platform is still in development, so I can only share some of my contribution at this time.',
    ],
    'images':['publett-1.png', 'publett-2.png', 'publett-3.png', 'publett-4.png', 'publett-5.png'],
    'link': 'See how the Daily Stand Up tool works'
  },
  'keepalive': {
    'name': 'keepalive',
    'title': 'Keep-Alive',
    'area': 'Developer Experience (DX) Tool Development',
    'tech': 'C#, Win UI',
    'strap': 'Enabling Full Tool Utilisation',
    'text': [
      'While working remotely, the development team and me needed to simulate virtual hardware testing scenaries. A problem encountered was that the devices would lock out and go to sleep after 15 minutes.',
      'To bypass this problem, I created a small utility tool that can be run from the Windows task manager bar. The tool allows a developer to force their device to not lock out or sleep after an authorised duration.',
      'Additionally, developers could enable a reset on mouse move option, the utitily successfully allowed testing scenarios to be run without interuptions.',
    ],
    'images':['keepalive-1.png'],
    'link': 'Take a quick look at Keep-Alive'
  },
  'jaii': {
    'name': 'jaii',
    'title': 'Sharp Job Accounting II (JAII)',
    'area': 'Web and Application Development',
    'tech': 'HTML, CSS, PHP, Javascript, PostgreSQL, Java, C, VLML',
    'strap': 'Job Control and Accounting',
    'text': [
      'Working with the Sharp Development team, I would primarily work on the admin portal and Sharp Multifunctional Printer (MFP) front end development. Design and functional requirements were delivered by the product owner.',
      'We would then estimate and break the work down into two-week development sprints.',
      'My greatest acheivement was delivering a custom made, single page application that supported the limited Netfront browser, giving JAII a unique advantage over our competitors. This was acheived with hand crafted ECMAScript, image sprites and Ajax.',
    ],
    'images':[
      'jaii-1.jpeg',
      'jaii-2.jpeg',
      'jaii-3.jpeg',
      'jaii-4.jpeg',
      'jaii-5.jpeg',
    ],
    'link': 'Find out more about JAII and my involvement'
  },
  'zeppelin': {
    'name': 'zeppelin',
    'title': 'Zeppelin Couriers',
    'area': 'Web Development',
    'tech': 'HTML, CSS, PHP, Javascript, SQL, Google Distance Matrix, reCaptcha',
    'strap': 'Instant Same-Day Delivery Cost Calculation',
    'text': [
      'Zeppelin Couriers are a new start up, offering same-day delivery with a one hour pick up window. The development brief was to create a website with the clients assets and as close to their presented design.',
      'The key request was the cost calculator, which uses Google Distance Matrix, along with package information (weight and dimensions) to generate a quote for the customer.',
      'During development regular progress meeting were conducted, and design changes implemented to make the site work for desktop and mobile clients.',
    ],
    'images':['zeppelincouriers-1.png','zeppelincouriers-2.png', 'zeppelincouriers-3.png','zeppelincouriers-4.png'],
    'link': 'More about Zepplin Couriers development and their services' 
  },
  'aakilasammy': {
    'name': 'aakilasammy',
    'title': 'Aakila Sammy',
    'area': 'Web Development',
    'tech': 'HTML, CSS, PHP, Javascript',
    'strap': 'Quick Delivery of MVP',
    'text': [
      'Dr Aakila Sammy, is a leading researcher into Ovarian cancer and nuclear motors at Brunel University, London. In 2018, she was presenting at the conference in Zurich.',
      'The requirment was for a basic website, that she could provide links to at the conference and publish her current and future work to. We completed the site in 2 evenings, before she travelled to the conference.',
      'Dr Sammy, has been supporting efforts to test and vaccinate against COVID, and is looking to publish new articles and update her website in 2022.',
    ],
    'images':['aakilasammy-1.png', 'aakilasammy-2.png', 'aakilasammy-3.png'],
    'link': 'More about Dr Aakila Sammy and her research'
  },
  'portfolio': {
    'name': 'portofolio',
    'title': 'This Portfolio',
    'area': 'Single Page Application (SPA) Development',
    'tech': 'React, HTML, CSS, PHP, Javascript',
    'strap': 'Sharing My Work For Review',
    'text': [
      'I developed this portfolio to allow me to provide further insight to my development experience, active research towards my PhD and to share a little look into my life.',
      'The development time from project initialisation to publishing was 4 evenings, including content capture, copy writting and code development.',
      'As a developer and researcher, I thrive in learning new skills and enjoy the challenge of completing a project as quickly as possible. If you would like to chat to me, please click below.',
    ],
    'images':[],
    'link': 'Contact Me'
  }

}

const Project = () => {
  return (
    <div className="profilePage">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="profileItems">
          <ProjectItem item={profileItems.jaii} />
          <ProjectItem item={profileItems.biomapper} />
          <ProjectItem item={profileItems.dailystandup} />
          <ProjectItem item={profileItems.publett} />
          <ProjectItem item={profileItems.editdistance} />
          <ProjectItem item={profileItems.keepalive} />
          <ProjectItem item={profileItems.zeppelin} />
          <ProjectItem item={profileItems.aakilasammy} />
          {/* <ProjectItem item={profileItems.portfolio} image="aakilasammy.png" /> */}
        </div>
      </div>
    </div>
  )
}

export default Project;