import logo from './logo.svg';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";
import Container from 'react-bootstrap/Container'

//import './App.css';
import Header from "./features/header/Header";
import MainNavigation from "./features/main-navigation/MainNavigation";
//import Profile from "./features/profile/Profile";
//import Project from "./features/project/Project";
//import Resume from "./features/resume/Resume";

function App() {
  return (
    <div style={{backgroundColor: '#eeeeee'}}>
      <div style={{backgroundColor: '#ffffff'}}>
        <Container>
         <Header />
        </Container>
      </div>

      <div style={{backgroundColor: '#ffffff'}}>
      <Container>
        <MainNavigation />
      </Container>
      </div>

      <Container style={{backgroundColor: '#eeeeee', padding: 15}}>
        <Outlet />
      </Container>
    </div>
  )
}

export default App;
