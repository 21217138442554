import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './Header.css';

const Header = () => {
  return (
      <div className="header">Matthew O'Neill</div>
  )
}

export default Header;